import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import PageHeader from "../components/elements/PageHeader";
import ButtonLink from "../components/elements/ButtonLink";
import {FaEnvelope, FaPhone} from "react-icons/all";

const Resources = () => (
  <Index>
    <Seo title="Resources" />
    <PageHeader>
      <h1>Resources</h1>
      <h2>The Tools You Need</h2>
      <p className="no-margins">
        <ButtonLink href="/request-demo" text="Schedule a Demo" type="primary" />
      </p>
    </PageHeader>
    <div id="contact-us-block">
      <h2 className="text-blue align-center large">Contact us</h2>
      <div id="contact-us-halves">
        <a href="tel:+18006595855">
          <FaPhone size="30" /> <br />
          (800) 659-5855
        </a>
        <a href="mailto:info@InCircleReview.com">
          <FaEnvelope size="30" /> <br />
          info@InCircleReview.com
        </a>
      </div>
      <p className="align-center">
        <ButtonLink href="/schedule-demo/" text="Schedule a Free Demo Today!" type="primary" />
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </Index>
)

export default Resources
